#title-container {
    height: 100%;
    color: #fff;
    background-color: rgba(193, 37, 37, 1);
    text-align: center;
    padding: 60px 28px 28px 28px;
    box-sizing: border-box;
    position: relative;
    box-shadow: 10px 8px 21px 0px rgba(204, 204, 204, 0.75);
    -webkit-box-shadow: 10px 8px 21px 0px rgba(204, 204, 204, 0.75);
    -moz-box-shadow: 10px 8px 21px 0px rgba(204, 204, 204, 0.75);
}

#title-container h2 {
    font-size: 45px;
    font-weight: 800;
    color: #fff;
    padding: 0;
    margin-bottom: 0px;
}

#title-container h3 {
    font-size: 25px;
    font-weight: 600;
    color: #82000a;
    padding: 0;
}

#title-container p {
    font-size: 0.9em;
    padding: 0 25px;
    line-height: 20px;
}



#wizard-content-container {
    background-color: #fff;
    position: relative;
    padding: 62px;
    height: 100%;
    box-shadow: 10px 8px 21px 0px rgba(204, 204, 204, 0.75);
    -webkit-box-shadow: 10px 8px 21px 0px rgba(204, 204, 204, 0.75);
    -moz-box-shadow: 10px 8px 21px 0px rgba(204, 204, 204, 0.75);
}

#title-container a:link {
    color: #fff;
}

/* visited link */
#title-container a:visited {
    color: #fff;
}

/* mouse over link */
#title-container a:hover {
    color: #fff;
}

/* selected link */
#title-container a:active {
    color: #fff;
}


.filter-red {
    filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(1000%) contrast(100%);
    width: 50%;
    height: auto;
}

@media screen and (min-width: 576px) {

    #wizard-container {
        min-height: calc(100vh - 60px);
    }

    #title-container {
        min-height: 460px;
    }

    #wizard-content-container {
        min-height: 630px;
    }
}