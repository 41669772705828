.modal-header-primary {
    color:#fff;
    padding:9px 15px;
    border-bottom:1px solid #eee;
    background-color: rgb(165, 44, 43);
    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
     border-top-left-radius: 5px;
     border-top-right-radius: 5px;
}

body {
  background-color: #EDF2F4;
  font-family: "Roboto";
}


body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

@media only screen and (min-width: 768px) {
  html {
      overflow: hidden;
  }
}